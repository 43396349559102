import React from "react"
// import { css } from "@emotion/react"
// import OutsideClickHandler from "react-outside-click-handler"
import "../../../css/popup.scss"
import logo from "../../../assets/images/FeelingBetterNowLogo.svg"
// import outerheart from "../../../assets/images/outerHeart.png"
// import innerheart from "../../../assets/images/innerHeart.png"
// import lefthand from "../../../assets/images/leftHand.png"
// import righthand from "../../../assets/images/rightHand.png"
// import wrist from "../../../assets/images/wrist.png"
// import circle from "../../../assets/images/checkmarkCircle.png"
// import check from "../../../assets/images/checkmarkCheckmark.png"
import rightellipse from "../../../assets/images/rightEllipse.png"
import leftellipse from "../../../assets/images/leftEllipse.png"
import { logEvent } from "../../../index"
import { withTranslation } from "react-i18next"
import logClickEvent from "../../../utils/logClickEvent"

const Checkmark = (props) => {
  var underline = (props.underline) ? "underline" : 'none';
  return (
    <div className="entire-checkmark">
      <img src={require('../../../assets/images/checkmark.svg').default} className="checkmark" />
      <p style={{ textDecoration: underline }} className="checkmark-text">{props.text}</p>
    </div>
  )
}
const SafetyNet = ({ t, i18n }) => {
  logEvent("Safety net triggered")
  return (
    <div
      className="modal fade harm-feeling-modal"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-center" role="document">
        <div className="modal-content safety-card">
          <div className="modal-header" style={{ borderColor: "transparent" }}>
            <button
              type="button"
              className="close x-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={{ color: "black" }}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-header-logo">
            <img src={logo} className="fbn-logo-self-harm" alt="" />
          </div>

          <div className="popup-design">
            <img src={require('../../../assets/images/illustration.svg').default} className="illustration" alt="" />
            {/* <div className="hearts">
              <img src={innerheart} className="inner-heart" />
              <img src={outerheart} className="outer-heart" />
            </div>

            <div className="whole-left-hand">
              <img src={lefthand} className="left-hand" />
              <img src={wrist} className="left-wrist" />
            </div>

            <div className="whole-right-hand">
              <img src={righthand} className="right-hand" />
              <img src={wrist} className="right-wrist" />
            </div> */}
          </div>

          <h3 className="harm-title mt-3">{t("selfHarmPopup.title")}</h3>
          <h3 className="harm-text">
            {t("selfHarmPopup.description")}
          </h3>

          <div className="checkmark-list">
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.crisisservicescanada.ca/en/"
              target="_blank"
              onClick={e => {
                e.preventDefault();
                logClickEvent('Click Crisis Link', 1, '')
                window.open(e.currentTarget.getAttribute('href'), '_blank')
              }}
            ><Checkmark underline={true} text={t("selfHarmPopup.helpTextPoint1")} /></a>
            <a
              href="tel:911"
              onClick={e => {
                e.preventDefault();
                logClickEvent('Click 911 Link', 1, '')
                window.open(e.currentTarget.getAttribute('href'))
              }}
            ><Checkmark underline={true} text={t("selfHarmPopup.helpTextPoint2")} /></a>
            <a
              href="https://988.ca"
              target="_blank"
              onClick={e => {
                e.preventDefault();
                logClickEvent('Click on company support resources', 1, '')
                window.open(e.currentTarget.getAttribute('href'), '_blank')
              }}
            >
              <Checkmark underline={true} text={t("selfHarmPopup.helpTextPoint3")} /></a>
          </div>

          <img src={rightellipse} className="safety-right-ellipse" alt="" />
          <img src={leftellipse} className="safety-left-ellipse" alt="" />

          <div className="modal-footer" style={{ borderColor: "transparent", margin: "2rem 0 3rem" }}>
            <button
              type="button"
              className="im-okay-button"
              data-dismiss="modal"
              onClick={() => {
                logClickEvent('Click OK, lets continue', 1, '')
              }}
            >
              {t("selfHarmPopup.continueBtn")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(SafetyNet)